import React from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import "./collectionVehicleSelectOption.css";
import VanIMG from "../../assets/images/van.png";
import CarIMG from "../../assets/images/car.png";
import ScooterIMG from "../../assets/images/scooter.png";
const parse = require('html-react-parser');

class CollectionVehicleSelectOption extends React.Component {
    constructor(props) {
        super(props);
        this.onOptionSelect = this.onOptionSelect.bind(this);

    }

    onOptionSelect = () => {
        if (this.props.isSelected == false) {
            this.props.onSelect(this.props.id)
        }
    };

    render() {
        let selectVehicleRootClass = this.props.isSelected ? "select-vehicle-root select-vehicle-root-selected" : "select-vehicle-root";
        selectVehicleRootClass = this.props.error ? "select-vehicle-root select-vehicle-root-error" : selectVehicleRootClass;

        let selectVehicleTickIcon = this.props.error ? "select-vehicle-tick-icon-error" : "select-vehicle-tick-icon";
        return (
            <>
                <div  id={this.props.display_name} onClick={this.onOptionSelect} className={selectVehicleRootClass}>
                    <div id={this.props.display_name} className="select-vehicle-img">
                        <img src={this.props.display_name == "Van" || this.props.display_name == "Lorry" ? VanIMG : this.props.display_name == "Car" ? CarIMG : ScooterIMG }/>
                    </div>
                    <div className="select-vehicle-desc">
                        <div className="select-vehicle-desc-name">Reinin {this.props.display_name}</div>
                        <div className="select-vehicle-desc-info">{this.props.description}</div>
                    </div>
                    <div className="select-vehicle-price">${this.props.fee}</div>
                    <div className="select-vehicle-icon">{this.props.isSelected ? (
                        <CheckCircleIcon className={selectVehicleTickIcon}/>) : ""}</div>
                </div>
                {this.props.error ? (
                    <div className="select-vehicle-error-message">
                        Collection cost exceeds your cart value
                    </div>
                ) : ""}
            </>
        )
    }
}

export default CollectionVehicleSelectOption;
