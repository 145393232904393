// Import dependencies
import React from "react";

// Import components
import {Collapse} from "antd";

// Import styles
import "./accordion.css";
import {Constants} from "../../constants/Constants";

// Import icons
import Plus from "../../assets/icons/plus-black.svg";
import Minus from "../../assets/icons/minus-black.svg";

// Define custom accordion panel
const {Panel} = Collapse;

/*
    Accordion component
*/
const AccordionList = ({title, content1, content2, content3, styles, defaultActiveKey}) => (
    <Collapse
        //className="accordion"
        defaultActiveKey={defaultActiveKey}
        bordered={false}
        accordion
        expandIconPosition="right"
        expandIcon={({isActive}) =>
            isActive ? (
                <svg width="14" height="3" viewBox="0 0 24 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.0002 4.16634H2.00016C1.0835 4.16634 0.333496 3.41634 0.333496 2.49967C0.333496 1.58301 1.0835 0.833008 2.00016 0.833008H22.0002C22.9168 0.833008 23.6668 1.58301 23.6668 2.49967C23.6668 3.41634 22.9168 4.16634 22.0002 4.16634Z" fill="#BDBDBD"/>
                </svg>

            ) : (
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z" fill="#BDBDBD"/>
                </svg>

            )
        }
        style={styles ? styles : {width: "100%", marginBottom: 25}}
    >
        <Panel className="checkout_accordion" header={title} key="1">
            <p>{content1}</p>
            <p>{content2}</p>
            <p>{content3}</p>
        </Panel>
    </Collapse>
);

export default AccordionList;
