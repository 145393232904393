import React from "react";
import CollectionVehicleSelectOption from "./collectionVehicleSelectOption";
import {connect} from "react-redux";

class CollectionVehicleSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSelection: this.props.defaultVehicle,
            checkoutValue: this.props.cartValue,
        };
        this.onOptionSelect = this.onOptionSelect.bind(this);
        this.isSelectionError = this.isSelectionError.bind(this);
    }

    onOptionSelect = (key) => {
        if (key !== this.state.currentSelection) {
            console.log("Selected Vehicle : " + key)
            this.props.onVehicleSelect(key);
            this.setState(() => ({
                currentSelection: key,
            }))
        }
    };

    isSelectionError(key) {
        /*
        let fee = this.props.isMember ?
            this.props.collectionVehicles[key]["member_fee"] :
            this.props.collectionVehicles[key]["non_member_fee"];
        return fee > this.state.checkoutValue;
        */
       return false;
    }

    render() {
        return (
            <div className="collectionVehicleSelect__container">
                {Object.keys(this.props.collectionVehicles).map((key) => {
                        return (
                            <CollectionVehicleSelectOption id={key} isSelected={this.props.notSelect ? "" : key === this.props.defaultVehicle}
                                                           onSelect={this.onOptionSelect}
                                                           vehicleInfo={this.props.collectionVehicles[key]}
                                                           display_name={this.props.collectionVehicles[key]["display_name"]}
                                                           description={this.props.collectionVehicles[key]["description"]}
                                                           error={(key === this.state.currentSelection) && this.isSelectionError(key)}
                                                           avatar={this.props.collectionVehicles[key]["avatar"]}
                                                           fee={this.props.isMember ?
                                                               this.props.collectionVehicles[key]["member_fee"] :
                                                               this.props.collectionVehicles[key]["non_member_fee"]
                                                           }
                            />
                        )
                    }
                )}
            </div>
        )
    }
}

export default connect()(CollectionVehicleSelect);
