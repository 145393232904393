// Import dependencies
import React from "react"

// Import components
import {DatePicker, Form, Select} from "antd"

// Import styles
import "./date-select.css"

/*
    Date Selector component
*/
const DateSelect = ({
                        form: {getFieldDecorator},
                        id,
                        error,
                        isRequired,
                        type,
                        disabledDate,
                        onChange,
                        label
                    }) => {
    const [focused, setFocused] = React.useState(false);

    const onFocus = () => {
        setFocused(true)
        // let elem = document.getElementById(id);
        //         // let rect = elem.getBoundingClientRect();
        //         // window.scrollTo(rect.x, rect.y)
    }

    const OnBlur = () => {
        setFocused(false)
    }

    const renderCalendarIcon = () => {
        return (
            <svg className="calendar_input_icon" width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 2H15V1C15 0.45 14.55 0 14 0C13.45 0 13 0.45 13 1V2H5V1C5 0.45 4.55 0 4 0C3.45 0 3 0.45 3 1V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 17C16 17.55 15.55 18 15 18H3C2.45 18 2 17.55 2 17V7H16V17ZM4 9H6V11H4V9ZM8 9H10V11H8V9ZM12 9H14V11H12V9Z" fill="black"/>
            </svg>
        )
    }

    return (
        <Form.Item>
            <div id={id} className={focused ? "select-outer-div select-outer-div-focused" : "select-outer-div"}>
                <label>{label}</label>
                {getFieldDecorator(id, {
                    rules: [
                        {
                            required: isRequired,
                            message: error,
                            type: type,
                        },
                    ],
                })(
                    <DatePicker
                        suffixIcon={renderCalendarIcon()}
                        style={{width: "100%"}}
                        showToday={false}
                        disabledDate={disabledDate}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={OnBlur}
                        placeholder={"Select"}
                    />
                )}
            </div>
        </Form.Item>
    )
}

export default DateSelect
