// Import dependencies
import React from "react";
import {Row, Col} from "antd";
import {navigate, Link} from "gatsby";
import {Form} from "antd";
import {Button} from 'antd';

// Import components
import Select from "../form/select/select";
import InputField from "../form/input/inputField";
import GeneralButton from "../form/buttons/GeneralButton";
import DateSelect from "../form/date/dateSelect";

// Import styles
import "../../pages/step.css";
import "../../pages/recycle.css";

// Import data
import {
    CollectionMethod,
    CollectionMethodInputs
} from "../../data/inputFields";
import TitleSection from "../sections/titleSection";
import {Separator} from "../separators/separator";

import CollectionVehicleSelect from "../collection-vehicle-select/collectionVehicleSelect";
import {getCartQuote} from "../../util/cart";
import AccordionList from "../accordion/accordionList";
import OpenWindow from "../../assets/icons/open-window.svg";
import FreePickup from "../../assets/images/free_pickups.png";


/*
    Form for the Step 3 of the checkout process page
*/
class Step2Form extends React.Component {

  handleSubmit = e => {
      let codePromo = this.props.codePromo;
      this.props.form.validateFields(err => {
          if (!err) {
              this.props.recordOrderDetails();
              this.props.vehicleStep();
              navigate("/step-3",{
                state: {
                    collectionFee: this.props.collectionFee,
                    codePromoPrev: codePromo,
                    creditPreference: this.props.creditPreference
                }
              });
          }
      });
  };

    render() {
        const {form, collectionMethod} = this.props;

        return (
          <Form>
          <div className="step_tittle">
          Schedule
            <p>Tell us when and where to collect your things</p>
          </div>
          <div className="step_container_flex">
            <Select
                isRequired
                disabled
                id="collection-method"
                placeholder="Select"
                error="Please select collection method."
                label="Collection Method"
                form={form}
                initialValue={collectionMethod}
                data={CollectionMethod}
                onChange={this.props.updateCollectionMethod}
                whiteLabel
            />
          </div>
          <div className="step_container_flex">
          {/* Extra input fields depending on Select value */}
          {CollectionMethodInputs[this.props.collectionMethod]
              ? CollectionMethodInputs[this.props.collectionMethod].map(
                  (item, i) =>
                      item.isSelect ? (
                          item.error === "Please select drop-off location" ? (
                              <Select
                                  isRequired
                                  group
                                  showSearch
                                  key={i}
                                  id={item.placeholder}
                                  label={item.placeholder}
                                  placeholder={"Select"}
                                  error={item.error}
                                  form={form}
                                  loading={this.props.dropOffLoading}
                                  data={this.props.dropOffData}
                                  onChange={value =>
                                      this.props.updateCollectionDetails(
                                          item.placeholder,
                                          value
                                      )
                                  }
                                  whiteLabel
                              />
                          ) : (
                              <Select
                                  isRequired
                                  key={i}
                                  id={item.placeholder}
                                  label={item.placeholder}
                                  placeholder={"Select"}
                                  error={item.error}
                                  form={form}
                                  data={
                                      item.error === "Please select collection time"
                                          ? this.props.getAvailableTimes(item.data)
                                          : item.data
                                  }
                                  onChange={value =>
                                      this.props.updateCollectionDetails(
                                          item.placeholder,
                                          value
                                      )
                                  }
                                  isSingapore={item.disabled ? item.disabled : false}
                                  whiteLabel
                              />
                          )
                      ) : item.isDate ? (
                          <DateSelect
                              key={i}
                              label={item.placeholder}
                              placeholder={"Select"}
                              id={item.id}
                              isRequired
                              form={form}
                              error={item.error}
                              type={item.type}
                              disabledDate={this.props.disabledDate}
                              onChange={(date, dateString) => {
                                  this.props.updateCollectionDetails(
                                      item.placeholder,
                                      date,
                                      dateString
                                  )
                              }
                              }
                          />
                      ) : (
                          <InputField
                              key={i}
                              isRequired
                              id={item.placeholder}
                              error={item.error}
                              type={item.type}
                              form={form}
                              placeholder={item.placeholder}
                              onChange={e =>
                                  this.props.updateCollectionDetails(
                                      item.placeholder,
                                      e
                                  )
                              }
                              initialValue={item.placeholder == "Postal code" ? this.props.contact_info.postal_code : item.placeholder == "Street name / Blk. / Unit No." ? this.props.contact_info.home_address : ""}
                              numeric={item.numeric}
                          />
                      )
              )
              : null}
          </div>
          <div className="step_tittle">
            Collection vehicle
            <p>Pick a vehicle size that’s appropriate for what you’re selling. The fee will be deducted from your cart value. Fees are waived for members.</p>
          </div>





                                <CollectionVehicleSelect
                                    collectionVehicles={this.props.collectionVehicles}
                                    defaultVehicle={this.props.defaultVehicle}
                                    notSelect={this.props.notSelect}
                                    isMember={this.props.isMember}
                                    onVehicleSelect={this.props.onVehicleSelect}
                                    cartValue={getCartQuote(this.props.cart, this.props.generalCart)}
                                />
                                <Link to="/pricing" className="select-vehicle-link">
                                    <div className="select-vehicle-root select-vehicle-root-members">
                                        <div className="select-vehicle-img">
                                            <img src={FreePickup}/>
                                        </div>
                                        <div className="select-vehicle-desc">
                                            <div className="select-vehicle-desc-name">FREE Pickup</div>
                                            <div className="select-vehicle-desc-info">Become a member</div>
                                        </div>
                                        <div className="select-vehicle-price">$3.97<span>per month</span></div>
                                        <div className="select-vehicle-icon"></div>
                                    </div>
                                </Link>
                                  <AccordionList
                                      className="accordion"
                                      title="How can I know the right size?"
                                      content1="Choose a Scooter if you’re selling small- to medium-sized lightweight items, such as phones, laptops, or just a handful of clothes or books."
                                      content2="Choose a Car if your order includes 2-3 big of bags of clothes or books, or if it includes 1-2 large electronics like televisions, microwaves, computer monitors, etc."
                                      content3="Choose a Van if your order includes more than 3 big bags of clothes or books, or more than 2 large electronics."
                                  />
                                <div className="checkout_footer_mobile">
                                <GeneralButton
                                    onClick={this.handleSubmit}
                                    title="Next"
                                    type="primary"
                                    fit
                                    htmlType="submit"
                                    margin={{display: "block", marginLeft: "auto"}}
                                />
                                <span className="cart_checkout_header_switch" onClick={this.props.onMobileTrigger}>{!this.props.mobileShow ? "Show order summary" : "Return to checkout"}</span>          
                                </div>

            </Form>

        );
    }
}

// Connect redux to component
const mapStateToProps = state => ({
    cart: state.cart,
    generalCart: state.generalCart,
})

export default Form.create({name: "checkout-2"})(Step2Form);
