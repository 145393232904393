import React from "react";
import ReactResizeDetector from "react-resize-detector";
import {navigate} from "gatsby";

import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import {PageContainer, TitleText} from "../styled-components/UILibrary";
import SectionSeparator from "../components/separators/sectionSeparator";
import {connect} from "react-redux";
import StepsIndicator from "../components/checkout/stepsIndicator";
import {Constants} from "../constants/Constants";
import GeneralButton from "../components/form/buttons/GeneralButton";
import {Separator} from "../components/separators/separator";
import LaunchIcon from '@material-ui/icons/Launch';
import OpenWindow from "../assets/icons/open-window.svg";
import AccordionList from "../components/accordion/accordionList";
import CollectionVehicleSelect from "../components/collection-vehicle-select/collectionVehicleSelect";
import {Button} from "antd";
import {getFirebase} from "../api/firebase";
import {pushOrderInformation} from "../redux/actions/checkoutAction";
import {getCartQuote} from "../util/cart";
import HeaderSeparator from "../components/separators/headerSeparator";
import CheckoutCart from "../components/checkout/checkout_cart";
import StatisticsPopup from "../components/form/forms/statisticsPopup";
import Logo from "../assets/icons/logo_green_login.svg";
import { Link } from "gatsby"

// step3
import moment from "moment";
import Step2Form from "../components/checkout/step2Form";
import { Address, CollectionMethod } from "../data/inputFields";

class Step2 extends React.Component {
    constructor(props) {
        super(props);
        this.onResize = this.onResize.bind(this);
        this.onCollectionVehicleSelection = this.onCollectionVehicleSelection.bind(this);
        this.recordOrderCollectionVehicleDetails = this.recordOrderCollectionVehicleDetails.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        // step3
        this.updateCollectionMethod = this.updateCollectionMethod.bind(this);
        this.updateCollectionDetails = this.updateCollectionDetails.bind(this);
        this.fetchDropOffLocations = this.fetchDropOffLocations.bind(this);    

        this.updatePromoCode = this.updatePromoCode.bind(this);
        this.applyPromoCode = this.applyPromoCode.bind(this);
        this.isBrowser = this.isBrowser.bind(this);

        const {
          location: { state },
        } = this.props;
        const {codePromoPrev, creditPreference} = state ? state : {};
        this.state = {
            contact_info: {
              postal_code: "",
              home_address: ""
            },
            currentWidth: 0,
            isMember: false,
            collectionVehicle: "",
            collectionFee: 0,
            notSelect: true,
            creditPreference: creditPreference,

            // step3
            collectionMethod: CollectionMethod[0].value, // always select "We pick up"
            collectionDetails: {},
            dropOffLoading: false,
            dropOffData: [],

            mobileShow: false,
            popupOpen: false,
            popupContent: "",
            refealCodesArray: [],
            codePromoInput: "",
            codePromo: codePromoPrev ? codePromoPrev : {code: "", value: 0},
        };
    }

    isBrowser = () => typeof window !== "undefined";

    componentWillMount() {
      if (this.isBrowser() && !this.props.loggedIn) {
        navigate("/login");
      }
    }


    updatePromoCode = (e) => {
      this.setState({ codePromoInput: e.target.value });
    }

    applyPromoCode = () => {
      if (this.state.refealCodesArray.includes(this.state.codePromoInput)) {
        this.setState({ codePromo: {code: this.state.codePromoInput, value: 5}});
      } else {
        this.setState({ codePromo: {code: "", value: 0}});
      }
    }

    componentDidMount() {
//    if (getCartQuote(this.props.cart, this.props.generalCart) < 15) {
  if (!(this.props.cart.length + this.props.generalCart.length)) {
    navigate("/");
  }
        let defaultVehicle = this.props.checkout.order_info && this.props.checkout.order_info.collection_vehicle ?
            this.props.checkout.order_info.collection_vehicle :
            this.props.configs["default_collection_vehicle"]

        this.setState({
            collectionVehicle: defaultVehicle,
            collectionFee: ""
        });

            const app = import("firebase/app");
            const firestore = import("firebase/firestore");

            Promise.all([app, firestore]).then(([firebase]) => {
              if (this.props.userToken) {
                this.setState({ dropOffLoading: true });
                getFirebase(firebase)
                  .firestore()
                  .collection("users")
                  .doc(this.props.userToken.uid)
                  .get()
                  .then(doc => {
                    if (doc.exists) {
                      this.setState({
                          contact_info: doc.data().contact_info,
                          isMember: doc.data().membership_info.is_member,
                          collectionFee: this.props.collectionVehicles[this.state.collectionVehicle]["member_fee"],
                          collectionDetails: {
                            address: doc.data().contact_info.home_address && doc.data().contact_info.home_address,
                            postal_code: doc.data().contact_info.postal_code && doc.data().contact_info.postal_code
                          }
                      });
                    }
                  })
                  .catch(/* ERROR */);
                  this.setState({ dropOffLoading: false});
              }
                getFirebase(firebase)
                  .firestore().collection("refeal_codes").get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                      this.state.refealCodesArray.push(doc.data().code);
                    });
                });

            });

    }


    togglePopup = (content) => {
      this.setState({
        popupOpen: !this.state.popupOpen,
        popupContent: content
      });
    }
    onCollectionVehicleSelection = vehicleId => {
        this.setState({
            notSelect: false,
            collectionVehicle: vehicleId,
            collectionFee: this.state.isMember ?
                this.props.collectionVehicles[vehicleId]["member_fee"] :
                this.props.collectionVehicles[vehicleId]["non_member_fee"]
        });
    };

    onResize = width => {
        this.setState({currentWidth: width});
    };


//step3
// Adds extra input fields depending on what is selected in
// "And which collection method?" field
updateCollectionMethod = value => {
  if (value === "I drop-off at POP station") {
    this.fetchDropOffLocations();
  }

  this.setState({ collectionMethod: value });
};

// Updates the details of collection method
updateCollectionDetails = (item, e, date) => {
  let temp = this.state.collectionDetails;
  // Check the type of input
  if (item === "Collection Date") temp["collection_date"] = date;
  else if (item === "Collection Time") temp["collection_time"] = e;
  else if (item === "Country") temp["country"] = e;
  else if (item === "Drop-off location") temp["drop-off_location"] = e;
  else if (item === Address) temp["address"] = e.target.value;
  else if (item === "Postal code") temp["postal_code"] = e.target.value;

  this.setState({ collectionDetails: temp });
};

// Limit date & time for 'we pick-up'
disabledDate = current => {
  // Cannot select days before today and today
  return current && current < moment().endOf("day");
};
getAvailableTimes = item => {
  // Check if date not selected
  if (!this.state.collectionDetails.hasOwnProperty("collection_date")) {
    return [];
  } else if (
    moment(this.state.collectionDetails.collection_date).diff(
      moment(moment().format("YYYY-MM-DD")),
      "days"
    ) === 1
  ) {
    var currentTime = moment().format("hha");
    let stopIndex = 0;

    for (let i = 0; i < item.length; i++) {
      if (item[i].value.slice(0, 4) === currentTime) {
        stopIndex = i;
        break;
      }
    }

    let result = item.slice(stopIndex, item.length);
    return result;
  } else if (
    moment(this.state.collectionDetails.collection_date).diff(
      moment(moment().format("YYYY-MM-DD")),
      "days"
    ) > 1
  ) {
    return item;
  } else {
    return [];
  }
};

// Fetches drop-off locations
fetchDropOffLocations = () => {
//  this.setState({ dropOffLoading: true });

  // Fetch drop-off locations
  const app = import("firebase/app");
  const firestore = import("firebase/firestore");

  let results = [];

  Promise.all([app, firestore]).then(([firebase]) => {
    getFirebase(firebase)
      .firestore()
      .collection("drop_off_locations")
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          results.push({
            title: doc.id,
            locations: doc.data().locations,
          });
        });

//        this.setState({ dropOffLoading: false, dropOffData: results });
      });
  });
};
// Sends data from this form to redux
recordOrderDetails = () => {
  this.props.dispatch(
    pushOrderInformation({
      credit_preference: this.state.creditPreference,
      credit_details: this.state.creditDetails,
    })
  );
};

// Sends data from this form to redux
recordOrderCollectionVehicleDetails = () => {

  this.props.dispatch(
    pushOrderInformation({
        collection_vehicle: this.state.collectionVehicle,
        collection_fee: this.state.isMember ? 0 :this.state.collectionFee,
          collection_method: this.state.collectionMethod,
          collection_details: this.state.collectionDetails,
    })
  );

};


    render() {
      const chevronRight = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path d="M2 1l1-1 4 4 1 1-1 1-4 4-1-1 4-4"></path></svg>;

        return (
          <Layout noFooter noHeader stepPage checkoutWhite noWhatsapp>
            <SEO title="Checkout" />
            <StatisticsPopup popupOpen={this.state.popupOpen} togglePopup={this.togglePopup} content={this.state.popupContent}/>

              <div className="checkout_page_container" style={this.state.mobileShow ? {height: "100%"} : {}}>
                <CheckoutCart
                  codePromo={this.state.codePromo}
                  updatePromoCode={this.updatePromoCode}
                  applyPromoCode={this.applyPromoCode}
                  togglePopup={this.togglePopup}
                  mobileShow={this.state.mobileShow}
                  onMobileTrigger={() => this.setState({mobileShow: !this.state.mobileShow})}
                  collectionFee={this.state.collectionFee}
                />
                {/* Process indicator */}
                <div className={!this.state.mobileShow ? "checkout_page_forms" : "checkout_page_forms checkout_page_forms_hide"}>
                <Link to="/" className="checkout_logo" >
                  <Logo />
                </Link>
                  <span className="step_indicator">Contact{chevronRight}<b>Schedule</b>{chevronRight}Add-ons{chevronRight}Review</span>
                  <div className="checkout_next_step">
                    <div className="step_tittle">Contact</div>
                    <span className="active" onClick={() => navigate("/step-1", { state: {codePromoPrev: this.state.codePromo} })}>Edit</span>
                  </div>
                  <Step2Form
                    mobileShow={this.state.mobileShow}
                    onMobileTrigger={() => this.setState({mobileShow: !this.state.mobileShow})}
                    
                    collectionMethod={this.state.collectionMethod}
                    updateCollectionMethod={this.updateCollectionMethod}
                    getAvailableTimes={this.getAvailableTimes}
                    updateCollectionDetails={this.updateCollectionDetails}
                    disabledDate={this.disabledDate}
                    recordOrderDetails={this.recordOrderDetails}
                    dropOffData={this.state.dropOffData}
                    dropOffLoading={this.state.dropOffLoading}

                    vehicleStep={this.recordOrderCollectionVehicleDetails}

                    collectionVehicles={this.props.collectionVehicles}
                    defaultVehicle={this.state.collectionVehicle}
                    isMember={this.state.isMember}
                    onVehicleSelect={this.onCollectionVehicleSelection}
                    cart={this.props.cart}
                    generalCart={this.props.generalCart}

                    currentWidth={this.state.currentWidth}

                    collectionFee={this.state.collectionFee}

                    codePromo={this.state.codePromo}

                    notSelect={this.state.notSelect}
                    contact_info={this.state.contact_info}

                    creditPreference={this.state.creditPreference}

                  />
                  <div className="checkout_next_step">
                    <div className="step_tittle">Add-ons</div>
                    <span>Edit</span>
                  </div>
                </div>
              </div>

          </Layout>

        );
      
    }
}

// Connect redux to component
const mapStateToProps = state => ({
    collectionVehicles: state.product.collectionVehicles,
    configs: state.product.configs,
    cart: state.cart,
    generalCart: state.generalCart,
    userToken: state.user.userToken,
    checkout: state.checkout,
    loggedIn: state.user.isLoggedIn
  })

export default connect(mapStateToProps)(Step2)